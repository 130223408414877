<template>
    <div class="paymentMethods">
        
        <Steps :steps="processSteps" :nbrProducts="nbrProducts" :active="2" />
        <div class="payment-methods-body">
                <div class="payment-method" v-for = "paymentMethod in paymentMethodsListToShow" :key="paymentMethod.id">
                    <div class="payment-method-name" @click="selectedMethod = paymentMethod.id">
                        <img src="@/assets/img/icone-cb.png">
                        <label :for="paymentMethod.id" class="paymentMethodName">{{paymentMethod.methodTitle}}</label>
                        <div class="payment-method-radio">
                            <div class="custom-radio-wrapper">
                                <span :class="{'custom-radio': true, 'checked': selectedMethod  === paymentMethod.id}"></span>
                                <input class="radio" type="radio" name="paymentMethod" v-bind:id="paymentMethod.id" :checked="selectedMethod  === paymentMethod.id"/>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="divider"></div>
            <div class="checkbox-group gts">
                <label for="gts" id="label-gts">{{$t('agreeTerms')}} {{$t('terms')}}</label>
                <div class="payment-method-radio">
                    <div class="custom-radio-wrapper" @click="termsAgreed = !termsAgreed">
                        <span :class="{'custom-radio': true, 'checked': termsAgreed}"></span>
                        <input type="checkbox" v-model="termsAgreed" id="gts" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="payment-methods-footer">
            <FooterComponent inPaymentMethods :disableCheckoutButton="!termsAgreed" @goToPayment="checkout"/>
        </div>
    </div>

</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    import SIPS from './PaymentMethods/Sips.vue'
    import Valinawithdraw from './PaymentMethods/Valinawithdraw.vue'
    import Loyalty from './PaymentMethods/Loyalty.vue'
    var Checkout = require('@/assets/js/Checkout');
    var Utils = require("@/assets/js/Utils");
    var Stats = require("@/assets/js/Stats");
    var MQTT = require("@/assets/js/Food/MqttService");
    import FooterComponent from "@/components/Food/FooterComponent";

    export default {
        name: 'PaymentMethods',
        components: {
            SIPS,
            Valinawithdraw,
            Loyalty,
            Steps,
            FooterComponent
        },
        data: function() {
            return{
                paymentMethodsList: [],
                fromRoute: null,
                alertContent : "",
                totalAmount: 0,
                customerBasket: null,
                currentLoyaltyPoints: 0,
                useLoyalty: "false",
                selectedMethod: 1,
                termsAgreed: false,
                processSteps: Utils.getSteps(),
                base : localStorage.getItem('DKC_base')
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            this.customerBasket = this.$parent.basket;
            this.totalAmount = this.customerBasket.totalAmount;
            this.paymentMethodsList = Checkout.getPaymentMethodsList(this);
            localStorage.setItem("DKC_ticket", JSON.stringify(this.customerBasket));
            localStorage.setItem("DKC_ticketAmount", this.totalAmount);
            if (this.$route.params.error) {
                this.alertContent = this.$t('paymentError')
            }
            MQTT.publishMessage("PAYMENT_LIST");
            localStorage.removeItem("DKC_loyaltyToUse");
            this.$parent.refreshCustomer();
            if(isNotNull(this.$parent.customer) && isNotNull(this.$parent.customer.loyalty)){
                this.currentLoyaltyPoints = this.$parent.customer.loyalty.loyaltyPoint;
            }else{
                this.currentLoyaltyPoints = 0;
            }
        },
        methods: {
            handleBack (fallback) {
                if (!this.fromRoute.name) {
                    this.$router.push(this.base + fallback);
                } else {
                    this.$router.push(this.base + "/basket");
                }
            },
            paymentCanceled(){
                this.alertContent = this.$t('paymentError')
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            showPaymentMethod(paymentMethod){
                if(paymentMethod.fileName == "Loyalty" && (this.totalAmount >= this.currentLoyaltyPoints || this.useLoyalty == "true")){
                    return false;
                }
                return true;
            },
            methodPaymentChoosen(callback){
                this.$parent.showLoader = true;
                var paymentMethodChoosen = this.paymentMethodChoosen;
                if(isNotNull(paymentMethodChoosen)){
                    var parameters = {};
                    parameters[paymentMethodChoosen] = 1;
                    Stats.setStats(parameters);
                    callback();
                }
            },
            checkout(){
                if(this.termsAgreed){
                    this.paymentMethodChoosen = this.selectedMethod
                    if(this.selectedMethod === 1){
                        MQTT.publishMessage("SELECT_PAYMENT", '{"method": "SIPS"}', ()=> {
                            this.$router.push(this.base + "/sips")
                        });
                    }else if(this.selectedMethod === 2){
                        MQTT.publishMessage("SELECT_PAYMENT", '{"method": "LANE"}', ()=> {
                            this.$router.push(this.base + '/scan?inPayment=true');
                        });
                    }
                }
            }
        },
        computed:{
            paymentMethodsListToShow(){
                return this.paymentMethodsList.filter(method => this.showPaymentMethod(method))
            },
            nbrProducts(){
                return this.customerBasket.products.reduce((acc, product) => {return acc + product.qty}, 0)
            }
        },
        watch:{
            useLoyalty: function () {
                if(this.useLoyalty == "true"){
                    localStorage.setItem("DKC_loyaltyToUse", this.currentLoyaltyPoints);
                    localStorage.setItem("DKC_ticketAmount", this.totalAmount - this.currentLoyaltyPoints);
                    this.totalAmount = this.totalAmount - this.currentLoyaltyPoints;
                }else{
                    localStorage.removeItem("DKC_loyaltyToUse");
                    localStorage.setItem("DKC_ticketAmount", this.totalAmount);
                    this.totalAmount = this.customerBasket.totalAmount;
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            /* eslint-disable */
            if(to.name != "FoodScan" && to.name != "FoodBasket"){
                this.methodPaymentChoosen(() => {
                    this.$parent.showLoader = false;
                    next();
                });
            }else{
                next();
            }
        }
    }
</script>